const Experience: React.FC = () => (
  <section className="container py-16 md:py-20" id="experience">
    <h2 className="text-center font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl">
      My work experience
    </h2>

    <div className="relative mx-auto mt-12 flex w-full flex-col lg:w-2/3">
      <span className="left-2/5 absolute inset-y-0 ml-10 hidden w-0.5 bg-grey-40 md:block"></span>
      {[
        {
          company: 'Govtech Edu Indonesia',
          duration: 'Oct 2022 - Present',
          role: 'Development Engineer Manager',
          description:
            `I'm being trusted to lead and manage a team of 4 - 6 engineers and in this position my responsibilities create a new culture for a new team and lead the team. The challenge in this role for me is learning a new technology as this team is more focused on data pipelining (ETL, DQM) and improving new architecture from existing systems.`,
        },
        {
          company: 'Bukalapak.com',
          duration: 'Mar 2017 - Oct 2022',
          role: 'Software Engineer & Engineering Manager',
          description:
            'Led the development of subscription systems and optimized backend services to improve efficiency and scalability. Focused on system design, data pipelines, and performance improvements.',
        },

        {
          company: 'Liberty Jaya',
          duration: 'Apr 2015 - Mar 2017',
          role: 'Software Developer',
          description:
            'Developed and maintained internal tools to support business operations, improving workflow efficiency and automation.',
        },
      ].map((job, index) => (
        <div className="mt-8 flex flex-col text-center md:flex-row md:text-left" key={index}>
          <div className="md:w-2/5">
            <div className="flex justify-center md:justify-start">
              <span className="shrink-0">
                {job.company}
              </span>
              <div className="relative ml-3 hidden w-full md:block">
                <span className="absolute inset-x-0 top-1/2 h-0.5 -translate-y-1/2 transform bg-grey-70"></span>
              </div>
            </div>
          </div>
          <div className="md:w-3/5">
            <div className="relative flex md:pl-18">
              <span className="absolute left-8 top-1 hidden h-4 w-4 rounded-full border-2 border-grey-40 bg-white md:block"></span>
              <div className="mt-1 flex">
                <i className="bx bxs-right-arrow hidden text-primary md:block"></i>
                <div className="md:-mt-1 md:pl-8">
                  <span className="block font-body font-bold text-grey-40">{job.duration}</span>
                  <span className="block pt-2 font-header text-xl font-bold uppercase text-primary">{job.role}</span>
                  <div className="pt-2">
                    <span className="block font-body text-black">{job.description}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  </section>
);

export default Experience;
