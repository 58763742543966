import React, { useState, useEffect } from 'react';

const Header: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const triggerNavItem = (id: string) => {
    document.querySelector(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  const sections = ['Main', 'About', 'Experience', 'Contact'];

  // Detect scroll to hide or show the menu based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`w-full z-50 top-0 py-3 sm:py-5 fixed bg-primary transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'
        }`}
      onMouseEnter={() => setIsVisible(true)}
    >
      <div className="container flex items-center justify-end">
        {/* Navigation, always visible on first page, visible on hover */}
        <nav className="lg:flex flex-row items-center space-x-6">
          {sections.map((section) => (
            <li className="group list-none" key={section}>
              <span
                onClick={() => triggerNavItem(`#${section.toLowerCase()}`)}
                className="cursor-pointer pt-0.5 font-header font-semibold uppercase text-white"
              >
                {section}
              </span>
              {/* Hover effect on each item */}
              <span className="block h-0.5 w-full bg-transparent group-hover:bg-yellow transition-colors duration-300"></span>
            </li>
          ))}
        </nav>

        {/* Mobile Menu Button */}
        <div className="block lg:hidden">
          <button onClick={() => setMobileMenu(true)}>
            <i className="bx bx-menu text-4xl text-white"></i>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenu && (
        <div className="pointer-events-auto fixed inset-0 z-70 min-h-screen bg-black bg-opacity-70 opacity-100 lg:hidden">
          <div className="absolute right-0 min-h-screen w-2/3 bg-primary py-4 px-8 shadow md:w-1/3">
            <button className="absolute top-0 right-0 mt-4 mr-4" onClick={() => setMobileMenu(false)}>
              <img src="/assets/img/icon-close.svg" className="h-10 w-auto" alt="Close Menu" />
            </button>
            <ul className="mt-8">
              {sections.map((section) => (
                <li className="py-2" key={section}>
                  <span
                    onClick={() => {
                      setMobileMenu(false);
                      triggerNavItem(`#${section.toLowerCase()}`);
                    }}
                    className="cursor-pointer pt-0.5 font-header font-semibold uppercase text-white"
                  >
                    {section}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
