import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Exp from './components/Experience';
import Contact from './components/Contact';
import './assets/styles/main.css';

const App: React.FC = () => (
  <div>
    <Header />
    <Hero />
    <About />
    <Exp />
    <Contact />
  </div>
);

export default App;
