import Connections from "./Connections";

const About: React.FC = () => (
  <section className="bg-grey-50 py-16 md:py-20" id="about">
    <div className="container mx-auto flex flex-col items-start lg:items-center"> {/* Changed flex-row to flex-col */}
      {/* Who am I Section */}
      <div className="w-full px-4 text-center lg:w-full lg:text-left mb-10"> {/* Added margin-bottom */}
        <h2 className="font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl">
          Who am I?
        </h2>
        <h4 className="pt-6 font-header text-xl font-medium text-black sm:text-2xl lg:text-3xl">
          I'm Michael Ryan, a Software Engineer / Engineering Manager
        </h4>
        <p className="pt-6 font-body leading-relaxed text-grey-20">
          I’m Michael Ryan, a passionate software engineer and data enthusiast with over 9 years of experience in IT. My journey started in Indonesia, and now I’m building my career in New Zealand, where I’ve embraced new opportunities and challenges. My expertise lies in backend development, data engineering, and system design, and I’ve had the privilege of leading complex technical projects while driving efficiency improvements.

          I’ve worked with languages like Golang, Ruby, and Python, and I enjoy building data pipelines and optimizing systems to create high-impact solutions. I love working with data and always look for ways to streamline processes and improve system performance. My past experiences at Bukalapak, GovTech Edu Indonesia, and Liberty Jaya have given me valuable insights into creating scalable, efficient systems, and I’m constantly learning to grow in my field.
        </p>
        {/* Connections section */}
        <div className="flex flex-col justify-center pt-6 sm:flex-row lg:justify-start">
          <div className="flex items-center justify-center sm:justify-start">
            <p className="font-body text-lg font-semibold uppercase text-grey-20">
              Connect with me
            </p>
            <div className="hidden sm:block">
              <i className="bx bx-chevron-right text-2xl text-primary"></i>
            </div>
          </div>
          <div className="flex items-center justify-center pt-5 pl-2 sm:justify-start sm:pt-0 text-primary">
            <Connections />
          </div>
        </div>
      </div>

      {/* Skills Section */}
      <h3
        className="pt-6 text-center font-header text-xl font-medium text-primary sm:text-2xl lg:text-3xl"
      >
        Related Skills
      </h3>
      <div className="w-full px-4 pl-0 pt-10 lg:w-full lg:pl-12 lg:pt-0">


        {/* Product Engineering / Web Skills */}
        <h3 className="font-body font-semibold text-black uppercase mt-8 mb-4">Product / Web Engineering Skills</h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            { skill: 'Golang', description: 'Backend development, building services, automation' },
            { skill: 'Ruby', description: '6 years of experience, particularly in web applications and system design' },
            { skill: 'PHP', description: 'Experience with web development frameworks' },
            { skill: 'React (learning)', description: 'Building dynamic user interfaces' },
            { skill: 'Tailwind CSS', description: 'Styling web applications' },
            { skill: 'TypeScript (learning)', description: 'Strong typing for JavaScript' },
            { skill: 'System Design', description: 'Designing scalable and efficient backend systems' },
            { skill: 'API Development', description: 'Creating robust RESTful APIs for applications' },
            { skill: 'Microservices Architecture', description: 'Developing and managing distributed systems' },
            { skill: 'Elasticsearch', description: 'Data search and analysis' },
            { skill: 'CI/CD', description: 'Continuous integration and delivery pipelines' },
            { skill: 'Google Cloud Platform (GCP)', description: 'BigQuery operations and data management' },
            { skill: 'AWS (learning)', description: 'Basic experience, growing knowledge of AWS services' },
          ].map((item, index) => (
            <div key={index} className={`pt-4 pb-4 pl-4 pr-4 rounded-lg bg-gray-200}`}>
              <div className="flex items-end justify-between">
                <h4 className="font-body font-semibold uppercase text-primary">{item.skill}</h4>
              </div>
              <div className="text-gray-600">{item.description}</div>
            </div>
          ))}
        </div>

        {/* Data Engineering Skills */}
        <h3 className="font-body font-semibold text-black uppercase mb-4">Data Engineering Skills</h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            { skill: 'Python', description: 'Data processing, scripting, automation' },
            { skill: 'SQL', description: 'Database querying, optimization, BigQuery integration' },
            { skill: 'Data Pipelines', description: 'Design, development, and maintenance for scalable systems' },
            { skill: 'BigQuery', description: 'Managing and processing large datasets for analytics' },
            { skill: 'DBT', description: 'Transforming and modeling data for efficient use' },
          ].map((item, index) => (
            <div key={index} className={`pt-4 pb-4 pl-4 pr-4 rounded-lg bg-gray-200}`}>
              <div className="flex items-end justify-between">
                <h4 className="font-body font-semibold uppercase text-primary">{item.skill}</h4>
              </div>
              <div className="text-gray-600">{item.description}</div>
            </div>
          ))}
        </div>

        {/* Management Skills */}
        <h3 className="font-body font-semibold text-black uppercase mb-4">management Skills</h3>
        <div className="grid grid-cols-2 gap-4">
          {[

            { skill: 'Squad Lead', description: 'Leading retros, resolving team issues, and guiding projects' },
            { skill: 'Collaboration', description: 'Working across teams and managing cross-functional tasks' },
            { skill: 'Mentorship', description: 'Supporting junior developers and fostering growth' },
            { skill: 'Agile Methodologies (Scrum/Kanban)', description: 'For efficient project management and team collaboration.' },
          ].map((item, index) => (
            <div key={index} className={`pt-4 pb-4 pl-4 pr-4 rounded-lg bg-gray-200}`}>
              <div className="flex items-end justify-between">
                <h4 className="font-body font-semibold uppercase text-primary">{item.skill}</h4>
              </div>
              <div className="text-gray-600">{item.description}</div>
            </div>
          ))}
        </div>

      </div>
    </div>
  </section>
);

export default About;
