import React, { useState, useEffect } from 'react';

const Connections = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Obfuscated email parts
    const emailName = 'tan.michael.ryan';
    const domain = 'gmail.com';

    // Reassemble the email
    const fullEmail = `${emailName}@${domain}`;
    setEmail(fullEmail);
  }, []);

  return (
    <div className="flex">
      <a href="https://gitlab.com/tanmichaelryan/" className="pl-4" target="_blank" rel="noreferrer">
        <i className="bx bxl-gitlab text-2xl hover:text-yellow"></i>
      </a>
      <a href="https://www.linkedin.com/in/tan-michael-ryan" className="pl-4" target="_blank" rel="noreferrer">
        <i className="bx bxl-linkedin text-2xl hover:text-yellow"></i>
      </a>
      <a href="https://www.instagram.com/tanmichaelryan" className="pl-4" target="_blank" rel="noreferrer">
        <i className="bx bxl-instagram text-2xl hover:text-yellow"></i>
      </a>
      {email && (
        <a href={`mailto:${email}`} className="pl-4">
          <i className="bx bx-envelope text-2xl hover:text-yellow"></i>
        </a>
      )}
    </div>
  );
};

export default Connections;
